const handleCollapse = () => {

	const collapses: NodeListOf<HTMLElement> = document.querySelectorAll('.js-collapse');

	if (collapses.length === 0) {
		return;
	}

	collapses.forEach((item: HTMLElement) => {
		let expanded: boolean = false;
		const toggler: HTMLElement|null = item.querySelector('.js-collapse-toggle');
		const content: HTMLElement|null = item.querySelector('.js-collapse-content');
		if (!toggler || !content) {
			return;
		}
		toggler.addEventListener('click', () => {
			expanded = !expanded;
			content.style.maxHeight = expanded ? `${content.scrollHeight}px` : '0';
			item.classList.toggle('active', expanded);
		});
	});

}

export default handleCollapse;