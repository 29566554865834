const handleNav = () => {

	const headerNav: HTMLElement|null = document.getElementById('header-nav');
	const main: HTMLElement|null = document.querySelector('main');
	const footerNav: HTMLElement|null = document.getElementById('footer-nav');
	
	const headerMenu: HTMLElement|null = document.getElementById('header-menu');

	const togglers: NodeListOf<HTMLElement>|null = document.querySelectorAll('.js-nav-toggler');

	if (headerNav && headerMenu && main && footerNav && togglers.length) {
		togglers.forEach((toggler: HTMLElement) => {
			toggler.addEventListener('click', () => {
				if(headerNav.classList.contains('active')) {
					document.body.classList.remove('nav-active');
					headerNav.classList.remove('active');
					main.classList.remove('active');
					footerNav.classList.remove('active');
					headerMenu.ariaHidden = 'true';
					togglers.forEach((toggler: HTMLElement) => {
						toggler.classList.remove('active');
						toggler.ariaExpanded = 'false';
					});
				} else {
					document.body.classList.add('nav-active');
					headerNav.classList.add('active');
					main.classList.add('active');
					footerNav.classList.add('active');
					headerMenu.ariaHidden = 'false';
					togglers.forEach((toggler: HTMLElement) => {
						toggler.classList.add('active');
						toggler.ariaExpanded = 'true';
					});
				}
			});
		});
	}
	
};

export default handleNav;