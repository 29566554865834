const handleAudioPlayer = () => {

	const audioPlayers: NodeListOf<HTMLElement> = document.querySelectorAll('.js-audio-player');

	if (audioPlayers.length === 0) {
		return;
	}

	audioPlayers.forEach((audioPlayer: HTMLElement) => {
		const audio: HTMLAudioElement|null = audioPlayer.querySelector('audio');
		const playButton: HTMLButtonElement|null = audioPlayer.querySelector('button.js-play');

		if (!audio || !playButton) {
			return;
		}

		playButton.addEventListener('click', () => {
			if (audio.paused) {
				audio.play();
				playButton.textContent = 'Stop';
				playButton.classList.add('is-playing');
			} else {
				audio.pause();
				audio.currentTime = 0;
				playButton.textContent = 'Écouter';
				playButton.classList.remove('is-playing');
			}
		});
	});

}

export default handleAudioPlayer;