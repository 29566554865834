const fontSizeStorageName = 'rennes-maurepas-fontsize';
const dyslexiaStorageName = 'rennes-maurepas-dyslexia';

const handleAccessibility = () => {

	const sizeRangeInput: HTMLInputElement|null = document.querySelector('input#enlargeText');
	const dyslexiaCheckbox: HTMLButtonElement|null = document.querySelector('button.js-dyslexia');

	if( !sizeRangeInput || !dyslexiaCheckbox ) {
		return;
	}

	let dyslexiaValue: boolean = (localStorage.getItem(dyslexiaStorageName) ?? false) === '1' ? true : false;
	let fontSizeValue: number = Number(localStorage.getItem(fontSizeStorageName)) ?? 0;

	// Dyslexia event
	dyslexiaCheckbox.addEventListener('click', () => {
		dyslexiaCheckbox.classList.toggle('active');
		dyslexiaValue = !dyslexiaValue;
		localStorage.setItem(dyslexiaStorageName, dyslexiaValue ? '1' : '0');
		setDyslexia(dyslexiaValue);
	});

	// Init values from cookies
	dyslexiaCheckbox.classList.toggle('active', dyslexiaValue);
	setDyslexia(dyslexiaValue);

	// Font size event
	sizeRangeInput.addEventListener('change', () => {
		fontSizeValue = Number(sizeRangeInput.value);
		localStorage.setItem(fontSizeStorageName, fontSizeValue.toString());
		setFontSize(fontSizeValue);
	});

	// Init values from cookies
	sizeRangeInput.value = fontSizeValue.toString();
	setFontSize(fontSizeValue);
}

const setFontSize = (fontSize:number = 0) => {
	document.documentElement.style.fontSize = `${16 * (1 + (0.15 * fontSize))}px`;
}

const setDyslexia = (setDyslexia:boolean = true) => {
	document.documentElement.style.setProperty('--font-primary', setDyslexia ? '"Open Dyslexic", sans-serif' : 'inherit');
    document.documentElement.style.setProperty('--font-secondary', setDyslexia ? '"Open Dyslexic", sans-serif' : 'inherit');
}

export default handleAccessibility;