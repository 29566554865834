const handleFilter = () => {

	const form: HTMLFormElement|null = document.querySelector('form.js-filter');

	if (!form) {
		return;
	}

	// Get the form data
	let url: string = '';

	// Event listeners
	Array.from(form.querySelectorAll('select')).forEach((select : HTMLSelectElement) => {
		select.addEventListener('change', function() {
			url = (select.value === '' || select.value === null) ? '' : select.value;
		});
	});

	// Submit form
	form.addEventListener('submit', (e) => {
		e.preventDefault();
		filter();
	});

	// Reset form
	form.addEventListener('reset', function() {
		url = ''
		filter();
	});

	// Filter function
	const filter = () => {

		window.location.href = url ? url : form.dataset.url;

	}

}

export default handleFilter;