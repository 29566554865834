const modal = () => {

    const modals: NodeListOf<HTMLElement> = document.querySelectorAll('[data-modal]');

    if(!modals.length) {
        return;
    }

    modals.forEach((modal: HTMLElement) => {

        const id = <string>modal.dataset.modal;

        const modalOpen: NodeListOf<HTMLElement> = document.querySelectorAll(`[data-modal-open="${id}"]`);

        const modalClose: NodeListOf<HTMLElement> = document.querySelectorAll(`[data-modal-close="${id}"]`);

        const modalToggle: HTMLElement|null = document.querySelector(`[data-modal-toggle="${id}"]`);

        //Prevent propagation from modal wrapper
        const modalBody: HTMLElement|null = modal.querySelector(`[data-modal-body]`);

        const open = () => {
            modal.classList.remove('fadeOut');
            modal.style.display = modal.dataset.modalDisplay ? modal.dataset.modalDisplay : 'block';

            setTimeout(() => {
                modal.classList.add('active');
            }, 10);

            document.body.addEventListener('click', close)
        }

        const close = () => {
            modal.classList.remove('active');
            modal.classList.add('fadeOut');

            setTimeout(() => {
                modal.style.display = 'none';
            }, 500);

            document.body.removeEventListener('click', close)
        }

        if(modalOpen.length) {
            modalOpen.forEach((el:HTMLElement) => {
                el.addEventListener('click', (e:Event) => {
                    e.stopPropagation();
                    open();
                });
            });
        }

        if(modalClose.length) {
            modalClose.forEach((el:HTMLElement) => {
                el.addEventListener('click', (e:Event) => {
                    e.stopPropagation();
                    close();
                    if(modalToggle) {
                        modalToggle.classList.remove('active');
                    }
                });
            });
        }

        if(modalToggle) {
            modalToggle.addEventListener('click', (e:Event) => {
                e.stopPropagation();
                modalToggle.classList.toggle('active') ? open() : close();
            });
        }

        modal.addEventListener('click', (e:Event) => {
            e.stopPropagation();
        });

        if(modalBody) {
            modalBody.addEventListener('click', (e:Event) => {
                e.stopPropagation();
            });
        }

    })

}

export default modal
