// Import the necessary modules...

import handleAccessibility from "./src/handleAccessibility.js";
import handleAudioPlayer from "./src/handleAudioPlayer.js";
import handleCollapse from "./src/handleCollapse.js";
import handleFilter from "./src/handleFilter.js";
import handleNav from "./src/handleNav.js";
import modal from "./src/modal.js";

window.addEventListener('DOMContentLoaded', function() {

    //wait until images, links, fonts, stylesheets, and js is loaded
    window.addEventListener("load", function() {

        // Initialize...
        handleAccessibility();
        handleNav();
        modal();

        if (document.querySelector('.js-audio-player')) {
            handleAudioPlayer();
        }

        if (document.querySelector('.js-collapse')) {
            handleCollapse();
        }

        if (document.querySelector('.js-filter')) {
            handleFilter();
        }

        if (document.querySelector('.js-lottie')) {
            import("./src/handleAnimation.js").then(module => {
                module.default();
            });
        }

        if (document.querySelector('.js-timeline')) {
            import("./src/handleTimeline.js").then(module => {
                module.default();
            });
        }

   });

});
